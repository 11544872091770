<template>
  <div>
    <nav class="navbar navbar-expand-md navbar-dark sticky-top bg-dark">
      <div class="container-fluid">
        <router-link class="navbar-brand" to="/" replace><img id="logo" :src="logo" :alt="name"></router-link>
        <template v-if="!isLoginPage">
          <button class="navbar-toggler" @click="toggleMenu()" type="button" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="navbar-collapse collapse" id="navbarCollapse">
            <ul class="navbar-nav ms-auto mb-2 mb-md-0">
              <li><hr class="dropdown-divider text-light"></li>
              <li class="nav-item"><router-link class="nav-link active" to="/about" replace>Om <em>Tese</em></router-link></li>
              <!--
              Todo - we have the following modi:
              * free to use for all (confirmation of messages through SMS or email)
              * can be used only by employees so first login
              -->
              <li v-if="app.isLoggedIn()" class="nav-item"><a class="nav-link active" @click="doLogout()">Logg av</a></li>
              <li v-if="app.isLoggedOut()" class="nav-item"><a class="nav-link active" @click="app.showLogin()">Logg på</a></li>
            </ul> 
          </div>
        </template>
      </div>
    </nav>

    <div id="container_vue" class="container">
      <router-view />
    </div>

    <div class="modal fade" id="infoModal" tabindex="-1" aria-labelledby="infoModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="infoModalLabel">...</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body overflow-auto" style="max-height:350px">
            <p class="description" id="infoModalDescription">...</p>
          </div>
          <div class="modal-footer">
            <button type="button" id="infoModalCancel" class="btn btn-secondary me-auto" data-bs-dismiss="modal">Cancel</button>
            <button type="button" id="infoModalOK" class="btn btn-tese text-light" data-bs-dismiss="modal">OK</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade login" id="loginModal" tabindex="-1" aria-labelledby="loginModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="loginModalLabel">
              <span class="login_only">Logg på</span>
              <span class="register_only">Registrer konto</span>
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body overflow-auto" style="max-height:350px">
            <div class="text-start">
              <p v-if="!app.user">
                <!--Du trenger å logge på eller lage en brukerkonto før du kan bruke appen.-->
              </p>
              <form>
                <div class="mb-2">
                  <input type="radio" class="form-check-input" name="username_type" v-model="username_type" id="username_type_phone" value="phone" onclick="document.getElementById('phone').focus()">
                  <label class="form-check-label ms-2 me-3" for="username_type_phone">Tlf.</label>
                  <input type="radio" class="form-check-input" name="username_type" v-model="username_type" id="username_type_email" value="email" onclick="document.getElementById('username').focus()">
                  <label class="form-check-label ms-2 me-3" for="username_type_email">E-post</label>
                </div>
                <div v-if="this.username_type=='phone'" class="input-group">
                  <span class="input-group-text" onclick="document.getElementById('country_code').select()">+</span>
                  <input type="number" name="country_code" id="country_code" v-model="country_code" class="form-control ps-2 pe-1" maxlength="3" min="1" max="998" style="flex-grow: 0;flex-basis: 60px;" @change="checkCountryCode()">
                  <input type="tel" name="phone" id="phone" autocomplete="phone" v-model="phone" class="form-control" autocapitalize="none" autocorrect="off" spellcheck="false">
                </div>
                <div v-else>
                  <input type="email" name="username" id="username" autocomplete="email" v-model="username" class="form-control mb-2" autocapitalize="none" autocorrect="off" spellcheck="false">
                </div>
                <div class="login_only">
                  <label for="password" class="my-2">Tilgangskode</label>
                  <input type="password" name="password" id="password" autocomplete="current-password" v-model="password" class="form-control" autocapitalize="none" autocorrect="off" spellcheck="false">
                </div>
                <div class="register_only">
                  <label for="password_register" class="my-2">Tilgangskode</label>
                  <input type="text" name="password_register" id="password_register" autocomplete="new-password" value="" class="form-control" autocapitalize="none" autocorrect="off" spellcheck="false">
                </div>
              </form>
            </div>
            <div class="feedback info tiny mt-4 mb-2">
              {{feedback}}
            </div>
          </div>
          <div class="modal-footer">
            <div class="login_only me-auto">
              Ny bruker? <u @click="toggleLoginRegister()">Opprett en konto.</u><br>
              <u @click="forgottenPassword()">Glemt</u> tilgangskode?
            </div>
            <div class="register_only me-auto">
              Allerede bruker? <u @click="toggleLoginRegister()">Logg på</u>
            </div>
            <button type="button" id="loginModalOK" class="btn btn-tese text-light" @click="doLogin()">OK</button>
          </div>
        </div>
      </div>
    </div>

    <footer class="fixed-bottom bg-dark text-center text-light small py-1" v-if="isMobile()">
      {{name}} - <span class="small">{{version}}</span><span v-if="!app.api.isOnline" class="small ps-2 blink"><i class="fas fa-wifi" style="color:red"></i></span>
    </footer>
  </div>
</template>

<style>
/* https://stackoverflow.com/a/51868264/4177565 */
@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');

/* https://stackoverflow.com/questions/36212722/how-to-prevent-pull-down-to-refresh-of-mobile-chrome */
html, body {
  overscroll-behavior-y: contain;
  font-family: 'Rubik', sans-serif;
}

/* Buttons with icons should have some spacing (unless they consist ONLY of an icon) */
button.no-icon-space i.fa, a.no-icon-space i.fa, button.no-icon-space svg, a.no-icon-space svg {
  margin-right: 0;
}
button i.fa, a i.fa, button svg, a svg {
  margin-right: 0.5em;
}
h1 {
  color: #e16d1a;
}
h2,h3,h4,h5,h6 {
  color: #fe850c;
}
.hidden {
  display: none;
}
.btn-tese {
  background-color: #2f7171;
  border-color: #2f7171;
  color: white;
}
.btn-tese:hover,
.btn:focus-visible {
  color: white !important;
  background-color: #355959 !important;
}
.icon-tese {
  color: #2f7171;
}
.tiny {
  font-size: 80%;
  line-height: 100%;
}
.nodecoration {
  text-decoration: none;
  color: inherit;
}
.missing {
  color: red;
}
.text-black-25 {
  /* Mostly used for the uploaded cloud icon */
  opacity: 0.25;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
}

/* tomSelect for selects */
.ts-wrapper .option p {
	display: block;
  padding: 0;
  margin: 0;
}
.ts-wrapper .option p:nth-child(n+2) {
	font-size: 12px;
	display: block;
	color: #a0a0a0;
}
.btn:disabled {
  background-color: #32628c;
}
fieldset:disabled * {
  /* This disables mouse interactions with tom-select elements */
  pointer-events: none;
}
fieldset:disabled .ts-wrapper {
  background-color: #e9ecef;
}
fieldset:disabled svg {
  display: none;
}
fieldset:disabled .btn,
fieldset:disabled .btn-check {
  opacity: 0.3 !important;
  filter: saturate(0);
}
/* Position the clear button (x) to the left of the dropdown caret */
.plugin-clear_button.focus.has-items .clear-button, .plugin-clear_button:hover.has-items .clear-button {
  margin-right: 2em !important;
}

#logo {
  height: 32px;
}
#app .navbar, footer.fixed-bottom {
  background-color: #94c9c9 !important;
}

/* When presenting the specified values, honor the white spacing and cr/lfs */
#infoModalDescription {
  white-space: pre-wrap;
}
#infoModalDescription td {
    width: 50%;
}

/* Pictures that were taken */
#pictures .picture {
	width: 100%;
	margin: 0 0.5em 0.5em 0;
	border: 1px solid gray;
}

/* Blinking */
@keyframes blinker {
  from { opacity: 1; }
  to { opacity: 0; }
}
.blink {
  animation: blinker 1s cubic-bezier(.5, 0, 1, 1) infinite alternate;
}
@keyframes half_blinker {
  from { opacity: 0.3; }
  to { opacity: 0.1; }
}
.blink {
  animation: blinker 1s cubic-bezier(.5, 0, 1, 1) infinite alternate;
}
.half_blinker {
  animation: half_blinker 1s cubic-bezier(.5, 0, 1, 1) infinite alternate;
}

#loginModal.login .register_only {
  display: none;
}
#loginModal:not(.login) .login_only {
  display: none;
}
</style>

<script>
import { Modal } from 'bootstrap';
import { useRoute } from 'vue-router';

export default {
  data() {
    return {
      server: this.app.server(),
      name: this.app.name,
      version: this.app.version,

      username_type: 'phone',
      country_code: 47,
      phone: '',
      username: (this.app.user ? (this.app.isUUID(this.app.user.username) ? '' : this.app.user.username) : ''),
      password: '',
      feedback: '',
    }
  },

  computed: {
    isLoginPage() {
      let route = useRoute();
      //&& !this.app.isLoggedIn()
      return (route.path == '/login');
    },
  },  

  setup() {
    const logo = require('../assets/logo-inverse.png');
    return { logo };
  },

  methods: {
    toggleMenu() {
      try {
        let el = document.getElementById('navbarCollapse');
        if (el) {
          el.classList.toggle('show');
        }
      } catch (error) {
        console.error(error);
      }
    },

    checkCountryCode() {
      if( this.country_code < 1 ) {
        this.country_code = 1;
      }
      if( this.country_code > 998 ) {
        this.country_code = 998;
      }
    },

    doLogout() {
      this.app.logout();
      this.$router.replace('/login');
    },

    doLogin() {
      console.log( 'App.vue.doLogin("' +this.username +'","' +this.password +'")' );
      if( this.username != '' && this.password != '' ) {
        this.feedback = 'Et øyeblikk..'
        this.app.login( '', this.username, this.password )
        .then( () => {
          this.feedback = 'Du er nå logget på.'
          document.getElementById( 'loginModal' )._modal.hide();
        })
        .catch( (error) => {
          this.feedback = 'Kunne ikke logge inn.'
          console.error(error);
          return false;
        });
      } else {
        this.feedback = 'Vennligst oppgi brukernavn og passord.'
        return false;
      }
    },

    isMobile() {
      return this.app.isMobile();
    },

    forgottenPassword() {
      console.log('forgotten password');
    },

    toggleLoginRegister() {
      let el = document.getElementById('loginModal');
      if( el.classList.contains('login')) {
        el.classList.remove( 'login' );
      } else {
        el.classList.add( 'login' );
      }
    }
  },

  mounted() {
    console.log('app.mounted');
    try {
      // Add the bootstrap modal references to the elements in the DOM
      for( var id of ['loginModal','infoModal']) {
        let modal = document.getElementById(id);
        modal._modal = new Modal(modal, {
          backdrop: true,
          keyboard: true,
          focus: true
        });
      }

      // When the info modal is closed, certain properties are reset (position, opacity of the backdrop, visibility of buttons, onclick of buttons)
      let infoModal = document.getElementById('infoModal');
      infoModal.addEventListener('hidden.bs.modal', () => {
        let modal = document.getElementById('infoModal');
        modal.style.top = '0px';
        //let backdrop = document.querySelector('.modal-backdrop.show');
        //backdrop.style.opacity = '0.05';
        let btns = document.querySelectorAll('#infoModal button');
        for( let btn of btns ) {
          btn.hidden = false;
          // Remove any onclick function - just removing the attribute didn't seem to work, so we set it to null first
          btn.onclick = null;
          btn.removeAttribute('onclick');
        }
      });
    } catch( error ) {
      console.error( error );
    }
  }
};
</script>