import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [{
        path: '/',
        name: 'home',
        component: function() {
            return import ( /* webpackChunkName: "share" */ '../views/home.vue')
        }
    },
    {
        path: '/login',
        name: 'login',
        component: function() {
            return import ( /* webpackChunkName: "share" */ '../views/login.vue')
        }
    },
    {
        path: '/about',
        name: 'about',
        component: function() {
            return import ( /* webpackChunkName: "share" */ '../views/about.vue')
        }
    },
    {
        path: '/debug',
        name: 'debug',
        component: function() {
            return import ( /* webpackChunkName: "share" */ '../views/debug.vue')
        }
    },
    {
        path: '/create',
        name: 'create',
        component: function() {
            return import ( /* webpackChunkName: "share" */ '../views/create.vue')
        }
    },
    {
        path: '/show/:id',
        name: 'show',
        component: function() {
            return import ( /* webpackChunkName: "share" */ '../views/show.vue')
        }
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default {
    install(vue) {
        router.install(vue);

        router.beforeEach((to, from, next) => {
            try {
                console.log('router.beforeEach(/' + from.name + ' -> /' + to.name + ')');

                // Collapse the navbar (some bootstrap issue causes it to not collapse)
                let el = document.getElementById('navbarCollapse');
                if (el) {
                    el.classList.remove('show');
                }

                next();
            } catch (error) {
                next({ path: '/' });
                console.error(error);
            }
        });
    }
}