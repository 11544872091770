export default class Camera {
    constructor() { }

    initialize(storage) {
        console.log('camera.initialize(<storage>)');
        let self = this;

        self.storage = storage;
        self.scanning = false;

        // Make the list of all devices available so we can change cameras
        // Ideally we'd only use "environment" cameras, but the result isn't consistent across browsers yet (2022-04-28)
        self.deviceCurrent = self.storage.get('app.camera.deviceLastUsed');
        self.devices = [];
        // navigator.mediaDevices.enumerateDevices().then((deviceInfos) => {
        //     console.table(deviceInfos)
        //     const videoDevices = deviceInfos.filter(device => device.kind === 'videoinput');
        //     self.devices = videoDevices;
        //     // for (let deviceInfo of deviceInfos) {
        //     //     if (deviceInfo.kind === 'videoinput') {
        //     //         self.devices.push(deviceInfo);
        //     //     }
        //     // }
        // }).catch((err) => {
        //     console.log("Camera search error :", err.message)
        // });
        navigator.mediaDevices.getUserMedia({ audio: true, video: false })
            .then(async () => {
                const devices = await navigator.mediaDevices.enumerateDevices({ video: true });
                const videoDevices = devices.filter(device => device.kind === 'videoinput');
                self.devices = videoDevices;
            });
    }

    cancel() {
        console.log('camera.cancel()');
        let self = this;

        try {
            self.scanning = false;
            self.video.srcObject.getTracks().forEach((track) => {
                track.stop();
            });
            self.canvas.classList.add('hidden');
            self.picture.classList.remove('hidden');

            self.btnShoot.hidden = true;
            self.btnCancel.hidden = true;
            self.btnCamSwitch.hidden = true;
            self.btnFlash.hidden = true;

            // Clean the picture: use a transparant pixel to prevent "broken image" symbol
            self.picture.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';
        } catch (error) {
            console.error(error);
        }
        return true;
    }

    switchCamera(callback) {
        console.log('camera.switchCamera(<callback>)');
        let self = this;

        // Find the current device if set, so we can select the next, if required
        let currentPos;
        try {
            // Find the position in the list of devices
            currentPos = self.devices.map(obj => obj.deviceId).indexOf(self.deviceCurrent);
            if (currentPos == -1) {
                // The last used camera was not found in the list of cameras, set it to the first camera in the list
                currentPos = 0;
            }

            // Switch to the next camera (if available, and we're currently showing a camera)
            if (!self.canvas.className.includes('hidden')) {
                if (currentPos < self.devices.length - 1) {
                    currentPos++;
                } else {
                    currentPos = 0;
                }
            }

            // Stop everything we've been doing
            if (self.video.srcObject) {
                self.video.srcObject.getTracks().forEach((track) => {
                    track.stop();
                });
            }
        } catch (error) {
            console.error(error);
            currentPos = 0;
        }
        self.deviceCurrent = self.storage.set('app.camera.deviceLastUsed', self.devices[currentPos].deviceId);

        // Don't forget to make the cam-switch button visible
        if (self.devices.length > 1) {
            if (self.elBtnCamSwitch) {
                self.elBtnCamSwitch.hidden = false;
            }
        }

        let constraints = {
            video: true,
            facingMode: { ideal: "environment" },
            aspectRatio: { ideal: 16 / 9 },
            width: { min: 800, ideal: 1200 }, // This is the width of the image, not the canvas
        };
        if (self.deviceCurrent) {
            constraints.video = {
                deviceId: { exact: self.deviceCurrent }
            };
        }

        // https: //stackoverflow.com/questions/49126616/mediadevices-getusermedia-image-cropped-zoomed-in-chrome
        navigator.mediaDevices
            .getUserMedia(constraints)
            .then((stream) => {
                self.picture.classList.add('hidden');
                self.canvas.classList.remove('hidden');

                self.video.setAttribute("playsinline", true); // required to tell iOS safari we don't want fullscreen
                self.video.srcObject = stream;
                self.video.play();

                self.scanning = true;
                self.tick();

                // When clicking the button assigned to react to taking the picture, run the shoot, with the callback
                // Install this only now, after the stream has started
                if (self.btnCamSwitch) {
                    self.btnCancel.hidden = false;
                    self.btnCancel.onclick = () => {
                        self.cancel();
                    };
                }
                if (self.btnShoot) {
                    self.btnShoot.hidden = false;
                    self.btnShoot.onclick = () => { self.shoot(callback) };
                }
                if (self.btnCamSwitch) {
                    self.btnCamSwitch.hidden = false;
                    self.btnCamSwitch.onclick = () => { self.switchCamera(callback) };
                }
                if (self.btnFlash) {
                    self.btnFlash.hidden = false;
                    console.log('todo. flash');
                }
            })
            .catch((error) => {
                console.error(error);
                document.getElementById('camera-error').innerText = 'Kamera er ikke tilgjengelig for øyeblikket.\nVennligst juster innstillingene og gi appen tilgang.'
            });
    }

    capturePhoto(idContainer, idPicture, idBtnShoot, idBtnCancel, idBtnCamSwitch, idBtnFlash, callback = null) {
        console.log('camera.capturePhoto(...)');
        let self = this;

        // Expects container element to have a canvas element inside, and the canvas element to have a video element inside
        self.container = document.getElementById(idContainer);
        self.picture = document.getElementById(idPicture);
        self.btnShoot = document.getElementById(idBtnShoot);
        self.btnCancel = document.getElementById(idBtnCancel);
        self.btnCamSwitch = document.getElementById(idBtnCamSwitch);
        self.btnFlash = document.getElementById(idBtnFlash);

        self.canvas = document.querySelector('#' + idContainer + ' canvas');
        self.canvas.height = self.canvas.width;
        self.video = document.querySelector('#' + idContainer + ' canvas video');

        // Remove any previous taken pictures
        //self.storage.remove('photo.' + id);

        // The buttons are shown after the camera has been initialized
        self.switchCamera(callback);
    }

    tick() {
        console.log('camera.tick()');
        try {
            this.canvas.width = 1200;
            this.canvas.height = 1200;

            let
                x = 0,
                y = 0,
                width = this.video.videoWidth,
                height = this.video.videoHeight;

            if (width > height) {
                x = -1 * (height - width) / 2;
                width = height;
            } else {
                y = -1 * (width - height) / 2;
                height = width;
            }

            this.canvas
                .getContext("2d")
                .drawImage(this.video, x, y, width, height, 0, 0, this.canvas.width, this.canvas.height);
        } catch (error) {
            console.error(error);
            // Ignore
        }
        if (this.scanning) {
            setTimeout(() => {
                this.tick()
            }, 33);
        }
    }

    shoot(callback) {
        console.log('camera.shoot(<callback>)');
        let self = this;

        try {
            if (self.scanning) {
                self.video.pause();
            }

            // Hide the buttons
            self.btnShoot.hidden = true;
            self.btnCancel.hidden = true;
            self.btnCamSwitch.hidden = true;
            self.btnFlash.hidden = true;

            self.scanning = false;
            self.video.srcObject.getTracks().forEach((track) => {
                track.stop();
            });

            self.canvas.classList.add('hidden');
            self.picture.classList.remove('hidden');

            // Do the callback, if specified
            if (callback) {
                callback(self.canvas.toDataURL("image/jpeg"));
            }
        } catch (error) {
            console.error(error);
        }
    }

    loadImage(image, data) {
        if (this.log) console.log('camera.loadImage(<image>,<data>)');
        image.src = data;
    }
}