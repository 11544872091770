export default class Storage {
    constructor() {
        // This class dispatches events when the database contents get changed
        // The various components listen for this to refresh their display
        this.log = false;
    }

    keys() {
        let result = [];
        for (let i = 0; i < localStorage.length; i++) {
            result.push(localStorage.key(i));
        }
        return result;
    }

    has(key) {
        if (localStorage[key]) {
            return true;
        } else {
            return false;
        }
    }

    get(key) {
        if (this.log) console.log('storage.get(' + key + ')');
        let content = localStorage.getItem(key);

        try {
            let contentObj = JSON.parse(content);
            return contentObj;
        } catch (error) {
            // console.error(error); - don't report any errors, just return the original value
            return content;
        }
    }

    getAll(key) {
        // Return all key/value pairs where key starts with key
        if (this.log) console.log('storage.getAll(' + key + ')');
        let self = this;

        let result = [];
        let keys = self.keys();
        for (let k in keys) {
            if (keys[k].substring(0, key.length) == key) {
                result.push(self.get(keys[k]));
            }
        }

        return result;
    }

    set(key, value, emitEvent = false) {
        if (this.log) console.log('storage.set(' + key + ',' + value + ',' + emitEvent + ')');
        let content = value;

        try {
            if (typeof value == 'object') {
                content = JSON.stringify(value);
            }
            localStorage.setItem(key, content);

            if (emitEvent) {
                // Send an event so other components can react to this if necessary
                window.dispatchEvent(new CustomEvent('storage-changed', {
                    detail: {
                        action: 'set',
                        key: key,
                        content: content
                    }
                }));
            }
            return value;
        } catch (error) {
            console.error(error);
            return value;
        }
    }

    remove(key, emitEvent = false) {
        if (this.log) console.log('storage.remove(' + key + ',' + emitEvent + ')');

        try {
            localStorage.removeItem(key);

            if (emitEvent) {
                // Send an event so other components can react to this if necessary
                window.dispatchEvent(new CustomEvent('storage-changed', {
                    detail: {
                        action: 'remove',
                        key: key
                    }
                }));
            }
        } catch (error) {
            console.error(error);
        }
        return true;
    }

    addTo(key, content, emitEvent = false) {
        if (this.log) console.log('storage.addTo(' + key + ',' + emitEvent + ')');
        let self = this;

        try {
            let original_value = self.get(key);
            if (!original_value) {
                original_value = [];
            }
            original_value.push(content);
            self.set(key, original_value);

            if (emitEvent) {
                // Send an event so other components can react to this if necessary
                window.dispatchEvent(new CustomEvent('storage-changed', {
                    detail: {
                        action: 'addTo',
                        key: key,
                        content: content
                    }
                }));
            }
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    getFrom(key, code) {
        if (this.log) console.log('storage.getFrom(' + key + ',' + code + ')');
        let self = this;

        try {
            let value = self.get(key);
            if (value) {
                return value[code];
            } else {
                return null;
            }
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    setIn(key, code, content, emitEvent = false) {
        if (this.log) console.log('storage.setIn(' + key + ',' + code + ',<content>,' + emitEvent + ')');
        let self = this;

        try {
            let original_value = self.get(key);
            if (!original_value) {
                original_value = {};
            }
            original_value[code] = content;
            self.set(key, original_value);

            if (emitEvent) {
                // Send an event so other components can react to this if necessary
                window.dispatchEvent(new CustomEvent('storage-changed', {
                    detail: {
                        action: 'setIn',
                        key: key,
                        code: code,
                        content: content
                    }
                }));
            }
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    removeFrom(key, code, emitEvent = false) {
        if (this.log) console.log('storage.removeFrom(' + key + ',' + code + ',' + emitEvent + ')');
        let self = this;

        try {
            let original_value = self.get(key);
            if (!original_value) {
                return true;
            }
            delete original_value[code];
            self.set(key, original_value);

            if (emitEvent) {
                // Send an event so other components can react to this if necessary
                window.dispatchEvent(new CustomEvent('storage-changed', {
                    detail: {
                        action: 'removeFrom',
                        key: key,
                        code: code
                    }
                }));
            }
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    unset(key, emitEvent = false) {
        if (this.log) console.log('storage.unset(' + key + ',' + emitEvent + ')');

        try {
            localStorage.removeItem(key);

            if (emitEvent) {
                // Send an event so other components can react to this if necessary
                window.dispatchEvent(new CustomEvent('storage-changed', {
                    detail: {
                        action: 'unset',
                        key: key
                    }
                }));
            }
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    clear(emitEvent = false) {
        if (this.log) console.log('storage.clear(' + emitEvent + ')');

        try {
            localStorage.clear();

            if (emitEvent) {
                // Send an event so other components can react to this if necessary
                window.dispatchEvent(new CustomEvent('storage-changed', {
                    detail: {
                        action: 'clear'
                    }
                }));
            }
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    getContent(key) {
        if (this.log) console.log('storage.getContent(' + key + ')');
        let self = this;

        let result = {
            title: 'Beklager, det oppsto en feil',
            category: '',
            fk_form: 0,
        };

        try {
            let result_lookup = self.get(key);
            if (result_lookup) {
                result = result_lookup;
            }
        } catch (error) {
            result.subtitle = error;
        }

        return result;
    }
}