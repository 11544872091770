import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";

import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";

// https://laracasts.com/discuss/channels/livewire/roles242-uncaught-referenceerror-tomselect-is-not-defined?page=1&replyId=754316
import "tom-select/dist/css/tom-select.bootstrap5.css";
window.TomSelect = require("tom-select");

// import runtime from "serviceworker-webpack-plugin/lib/runtime";
// if ("serviceWorker" in navigator) {
//     runtime.register();
// }

import app from "./App.vue";
import router from "./router";
import { createApp } from "vue";
const vueApp = createApp(app);

import Console from "./scripts/Console.js";
import Storage from "./scripts/Storage.js";
import API from "./scripts/API.js";
import Camera from "./scripts/Camera.js";
import Application from "./scripts/Application.js";
import "./registerServiceWorker";

window.storage = new Storage();
window.application = new Application({
  storage: window.storage,
  api: new API(),
  camera: new Camera(),
  console: new Console(),
});

window.application
  .initialize()
  .then(() => {
    console.log("main.js.initialized");
    vueApp.config.globalProperties.app = window.application;
    vueApp.use(router, vueApp).mount("#app");
  })
  .catch((error) => {
    console.error(error || "Error");
    document.getElementById("app_start_title").innerText =
      "Beklager, applikasjonen starter ikke...";
    document.getElementById("app_start_description").innerHTML =
      "Applikasjonen trenger å laste ned noe info men kan ikke koble seg til tjeneren.<br>Vennligst sjekk internett-tilkoblingen og prøv igjen.";
  });
