const md5 = require("md5");
const sha1 = require("sha1");

export default class Application {
    constructor(args) {
        try {
            // Copy everything given to the constructor; storage and api are expected
            // Storage will be sent along so the API can properly initialize
            for (let prop in args) {
                this[prop] = args[prop];
            }

            // Get stuff from config
            this.name = config.VUE_CONFIG_APP_NAME;
            this.version = 'v' + process.env.VUE_APP_VERSION;

            // Get stuff from the local storage
            // Site is available only if it has been registered; unregistered sites can't be used
            this.user = this.storage.get('user');
            this.site = this.storage.get('app.site');
            this.properties = ['id', 'domain', 'site'];
            for (let prop of this.properties) {
                this[prop] = this.storage.get('app.' + prop);
            }

            // If the username and password is missing from localStorage, create them
            if (!this.user) {
                this.user = this.storage.set('user', {
                    uuid: this.generateUUID(),
                    password: sha1(md5(this.generateUUID()))
                });
            }

            // These are the timer values used to control the timeout before the upload and download functions are called again
            this.uploadTimerFast = 250;
            this.uploadTimerSlow = 10000;
            this.downloadTimer = 30000;
        } catch (error) {
            console.error(error);
        }
    }

    initialize() {
        console.log('app.initialize()');
        let self = this;

        // Initialization of the API may take some time as it may want to login at the server, so make it a promise
        return new Promise(function(resolve, reject) {
            try {
                self.api.initialize(self.storage);

                // Initialize the console (don't post logs, do post errors)
                if (self.console) {
                    if (window.location.host != 'localhost:8080' && self.api.server) {
                        self.console.initialize(false, true, 'https://' + self.api.server + self.api.server_path + 'console');
                    }
                }

                // No need to pickup the hostname - the same app and api is served to all requests
                // E.g. demo.tese.no and remark.tese.no will get the same app (which doesn't carry content)
                // They will talk to the same api, and that one will pickup the hostname
                // If we later need to separate subdomains stuff out, that can be done at subdomain level

                // Login to the app; send along the user credentials and a list of post ids
                // Store the returned token, then start the upload and download processes
                fetch('https://' + self.api.server + self.api.server_path + 'site', {
                    method: 'post',
                    body: new URLSearchParams({
                        uuid: self.user.uuid,
                        password: self.user.password //,
                            //'already_downloaded': self.storage.getAll('post.').map(o => o.id)
                    })
                }).then((response) => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error('Something went wrong');
                }).then((response) => {
                    // The response contains some site settings AND a token to be used for future communication
                    response = response.data;
                    self.api.token = self.storage.set('api.token', response.user.token);
                    self.site = self.storage.set('app.site', response.content);

                    // Start the upload and download processes when we have heard back from the server
                    // Todo: this means we need to restart the app to get this going...
                    if (self.user) {
                        self.upload();
                        self.download();
                    }
                    resolve();
                }).catch((error) => {
                    console.error(error);
                    reject(error);
                });
            } catch (error) {
                console.error(error);
                reject(error);
            }
        });
    }

    server() {
        return (window.location.href + '#').split('#')[0]
    }

    isLoggedIn() {
        let self = this;
        if (self.user) {
            if (self.user.logged_in) {
                console.log('app.isLoggedIn(): true');
                return true;
            }
        }
        console.log('app.isLoggedIn(): false');
        return false;
    }

    isLoggedOut() {
        let self = this;
        if (self.user) {
            if (!self.user.logged_in) {
                console.log('app.isLoggedOut(): true');
                return true;
            }
        }
        console.log('app.isLoggedOut(): false');
        return false;
    }

    requiresLogin() {
        let self = this;
        // If we have no token or if it is empty, login is required
        if (!self.api.token) {
            return true;
        }
        if (self.api.token == '') {
            return true;
        }
        return false;
    }

    siteExists() {
        let self = this;
        if (self.site) {
            return true;
        }
        return false;
    }

    isMobile() {
        return (/iPhone|iPad|iPod|Android|Opera\sMini|Windows\sPhone/i.test(navigator.userAgent));
    }

    isOnline() {
        let self = this;
        return self.api.isOnline;
    }

    isStandalone() {
        // https://stackoverflow.com/a/51735941/4177565
        return (window.matchMedia('(display-mode: standalone)').matches);
    }

    GMTToLocal(GMTTime = null) {
        var date = GMTTime ? new Date(GMTTime) : new Date();
        return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().substring(0, 19).replace('T', ' ');
    }

    getGPS(callback) {
        console.log('app.getGPS(<callback>)');

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    callback(position);
                },
                (error) => {
                    console.error(error);
                    callback(null);
                }, {
                    maximumAge: 0,
                    timeout: 5000,
                    enableHighAccuracy: true
                }
            );
        } else {
            callback(null);
        }
    }

    isUUID(s) {
        // https://stackoverflow.com/a/55138317/4177565
        s = "" + s;
        s = s.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
        if (s === null) {
            return false;
        }
        return true;
    }

    generateUUID() {
        // https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid
        if (crypto) {
            return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
                (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
            );
        } else {
            let d = new Date().getTime();
            let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                let r = Math.random() * 16;
                if (d > 0) { // Use timestamp until depleted
                    r = (d + r) % 16 | 0;
                    d = Math.floor(d / 16);
                } else { // Use microseconds since page-load (if supported)
                    r = (d2 + r) % 16 | 0;
                    d2 = Math.floor(d2 / 16);
                }
                return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
            });
        }
    }

    escapeHTML(html) {
        return String(html).replaceAll('<', '&lt;').replaceAll('>', '&gt;');
    }

    parseNumber(value) {
        // Parse value as float, but replace the european comma with a dot first
        if (value.trim() == '') {
            return 0;
        }
        return parseFloat(value.trim().replaceAll(' ', '').replaceAll(', ', '.'));
    }

    alert(message, title = '', isHTML = false) {
        console.log('app.alert(' + message + ',' + title + ',' + isHTML + ')');
        let self = this;

        // Load the title and text
        document.getElementById('infoModalLabel').innerText = title || self.name;
        var el = document.getElementById('infoModalDescription');
        if (isHTML) {
            el.classList.remove('text');
            el.innerHTML = message;
        } else {
            el.classList.add('text');
            el.innerText = message;
        }

        // Hide the Cancel button, prepare the OK button
        document.getElementById('infoModalCancel').hidden = true;
        document.getElementById('infoModalOK').hidden = false;
        document.getElementById('infoModalOK').removeAttribute('onclick');

        // Show the modal with the overview
        document.getElementById('infoModal')._modal.show();
    }

    showLogin() {
        console.log('app.showLogin())');
        document.getElementById('loginModal')._modal.show();
    }

    confirm(message, title = '', cbOK = null, cbCancel = null, isHTML = false) {
        console.log('app.confirm(' + message + ',' + title + ',<cbOK>,<cbCancel>,' + isHTML + ')');
        let self = this;

        // Load the title and text
        document.getElementById('infoModalLabel').innerText = title || self.name;
        var el = document.getElementById('infoModalDescription');
        if (isHTML) {
            el.classList.remove('text');
            el.innerHTML = message;
        } else {
            el.classList.add('text');
            el.innerText = message;
        }

        // Show the Cancel and OK buttons, prepare them 
        document.getElementById('infoModalCancel').hidden = false;
        document.getElementById('infoModalCancel').onclick = function() {
            // Reset the onclick itself so it won't happen next time
            document.getElementById('infoModalCancel').removeAttribute('onclick');
            // Close the popup
            self.popupInfoClose();
            // Do what needs to be done, if there is anything
            if (cbCancel) { cbCancel() }
        }
        document.getElementById('infoModalOK').hidden = false;
        document.getElementById('infoModalOK').onclick = function() {
            // Reset the onclick itself so it won't happen next time
            document.getElementById('infoModalOK').removeAttribute('onclick');
            // Close the popup
            self.popupInfoClose();
            // Do what needs to be done, if there is anything
            if (cbOK) { cbOK() }
        }

        // Show the modal with the overview
        document.getElementById('infoModal')._modal.show();
    }

    md5(s) {
        return md5(s);
    }
    sha1(s) {
        return sha1(s);
    }

    login(server = '', username = '', password = '', forceServer = false) {
        console.log('app.login(' + server + ',' + username + ',<password>,' + forceServer + ')');
        let self = this;

        if (self.storage.has('info.user') && !forceServer && !self.isOnline()) {
            return new Promise(function(resolve, reject) {
                console.log('Logging in locally');
                let users = self.storage.get('info.user');
                let password_hash = sha1(md5(password));
                for (let u in users) {
                    if (users[u].username == username && users[u].password == password_hash) {
                        // The token is set to something not empty, so this will allow the user to use the app locally
                        // But before synchronizing, the user will have to authenticate
                        self.user = {
                            'id': users[u].id,
                            'token': 'local-login',
                            'username': users[u].username,
                        };
                        self.storage.set('user', self.user);
                        resolve(true);
                    }
                }
                reject();
            });
        } else {
            return new Promise(function(resolve, reject) {
                console.log('Logging in remotely');
                self.api.login(server, username, password)
                    .then(result => {
                        self.user = self.storage.set('user', result);
                        resolve(true);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        }
    }

    logout() {
        console.log('app.logout()');
        let self = this;

        try {
            // Remove the token and store the new user object
            delete self.user.token;
            self.user = self.storage.set('user', self.user);
            return true;
        } catch (error) {
            console.error(error);
            return true;
        }
    }

    executeScripts(containerElement) {
        // This is used by loadForm
        // https://stackoverflow.com/a/69190644/4177565
        let self = this;
        try {
            const scriptElements = containerElement.querySelectorAll("script");

            Array.from(scriptElements).forEach((scriptElement) => {
                const clonedElement = document.createElement("script");

                Array.from(scriptElement.attributes).forEach((attribute) => {
                    clonedElement.setAttribute(attribute.name, attribute.value);
                });

                clonedElement.text = scriptElement.text;

                scriptElement.parentNode.replaceChild(clonedElement, scriptElement);
            });
        } catch (error) {
            console.error(error);
            self.alert('An error occurred when loading the form; please contact application support.');
        }
    }

    loadInfo(info) {
        console.log('app.loadInfo(<info>)');
        let self = this;

        // Figure out if the document is in edit or read mode
        var mode = document.querySelector('#container_app[data-lb-mode]').dataset.lbMode;

        // Reset all inputs
        document.querySelectorAll('form[data-lb-name]').forEach(f => {
            f.reset();
        });

        // Set the read-only values (html) and the inputs
        return new Promise(function(resolve, reject) {
            try {
                // Set the field values into the DOM, if any
                if (info) {
                    // The fields values in info.data have preference over those in info
                    for (let set of[info.data, info]) {
                        if (set) {
                            for (let field in set) {
                                let els = document.querySelectorAll('[data-lb-field="' + field + '"]');
                                for (let cnt = 0; cnt < els.length; cnt++) {
                                    els[cnt].innerHTML = set[field];
                                }

                                var el = document.getElementsByName(field)[0];
                                var value = set[field];
                                if (el) {
                                    // Split multiple values, based on ","
                                    if (el.type == 'select' || el.type == 'checkbox') {
                                        // Split values - if there is no "," this results in an array with one value
                                        value = value.split(',');
                                    }

                                    // Set the value, based on the element type
                                    if (el._TomSelect) {
                                        el._TomSelect.setValue(value, true);
                                        console.log('Setting tomselect ' + el.id + ' to ' + value);
                                    } else if (el.type.toLowerCase() == 'radio') {
                                        // Radio, set the single value
                                        let elInput = document.querySelector('input[name="' + el.name + '"][value="' + value + '"]');
                                        if (elInput) {
                                            window.application.logboek.doShowHide(elInput);
                                            elInput.checked = true;
                                        }
                                    } else if (el.type.toLowerCase() == 'checkbox') {
                                        // Checkbox, set (optionally) multiple values
                                        for (let val of value) {
                                            let elInput = document.querySelector('input[name="' + el.name + '"][value="' + val + '"]');
                                            if (elInput) {
                                                window.application.logboek.doShowHide(elInput);
                                                elInput.checked = true;
                                            }
                                        }
                                    } else if (el.dataset && el.dataset.lbClass == 'json') {
                                        // This element is used for lists etc, so put that content as stringified json into the input element
                                        el.value = JSON.stringify(value);
                                    } else {
                                        // Inputs such as textarea or of type text, number, etc
                                        el.value = value;
                                    }
                                } else {
                                    //console.log('Missing element with name ' + i);
                                }
                            }
                        }
                    }
                }

                // Picture inputs have an attribute that dictates where to display the images
                document.querySelectorAll('[data-lb-picture-display]').forEach(e => {
                    self.showImages(e);
                });

                // Attachment inputs have an attribute that dictates where to display the attachment names/links
                document.querySelectorAll('[data-lb-attachment-display]').forEach(e => {
                    self.showAttachments(e);
                    //self.showAttachments(document.querySelector(e.dataset.lbAttachmentDisplay));
                });

                // Scroll to the top, initially
                window.scrollTo(0, 0);

                // Finally, put the inputs in disabled state, if the form is in read mode
                document.forms[0].querySelectorAll('input,textarea,select').forEach(e => e.disabled = (mode == 'read'));

                resolve(info);
            } catch (error) {
                console.error(error);
                reject(info);
            }
        });
    }

    rememberFieldValues(values) {
        // Called before the form is saved to remember the default field values
        console.log('app.rememberFieldValues(<values>)');
        let self = this;

        // Loop through the html elements, check for data-remember=true
        let remember = self.storage.get('app.remember') || {};

        let hasChanged = false;
        for (let id in values) {
            let el = document.getElementById(id);
            if (el) {
                if (el.hasAttribute('data-properties')) {
                    let properties = {};
                    try {
                        properties = JSON.parse(el.getAttribute('data-properties'));
                    } catch (error) {
                        console.error('Could not parse properties of ' + el.id);
                    }

                    if (properties.remember) {
                        remember[id] = values[id];
                        hasChanged = true;
                    }
                }
            }
        }

        if (hasChanged) {
            self.storage.set('app.remember', remember);
        }
    }

    editInfo() {
        console.log('app.editInfo()');
        let self = this;

        var mode = 'add';
        if (self.record.id) {
            mode = 'edit';
        }

        // Turn the form into edit/add mode
        document.forms[0].querySelectorAll('input,textarea,select').forEach(e => e.disabled = false);
        document.querySelector('#container_app[data-lb-mode]').dataset.lbMode = mode;
    }

    cancelInfo() {
        console.log('app.cancelInfo()');
        document.querySelector('#container_app[data-lb-mode]').dataset.lbMode = 'read';
        if (this.record.id) {
            this.loadInfo(this.record);
        } else {
            this.unloadForm(true);
        }
    }

    saveInfo(key, id, type, contents, fk_post = null) {
        console.log('app.saveInfo(' + key + ',' + id + ',' + type + ',<contents>,' + fk_post + ')');
        let self = this;

        let record = {
            id: id,
            type: type,
            storage: 'local',
            createdAt: window.application.GMTToLocal(),
            data: contents,
        };
        if (fk_post) {
            record.fk_post = fk_post;
        }
        return self.storage.set(key, record);
    }

    popupInfoClose() {
        console.log('app.popupInfoClose()');

        try {
            document.getElementById('infoModal')._modal.hide();
        } catch (error) {
            console.error(error);
        }
    }

    download() {
        console.log('app.download()');
        let self = this;

        // If we're offline, exit rightaway
        if (!self.isOnline()) {
            return window.setTimeout(() => {
                self.download();
            }, self.downloadTimer);
        }

        // Fetching posts and comments: first get a list of uuid's that have been changed
        self.api.doPost('get_info', null, { 'since': self.storage.get('sync.downloaded') }).then((result) => {
            if (result && result.data) {
                var wanted = {
                    posts: [],
                    comments: [],
                }

                // Check the list of posts that have been changed, and see what we want
                if (Object.keys(result.data.posts).length > 0) {
                    for (var id in result.data.posts) {
                        if (self.storage.has('post.' + result.data.posts[id])) {
                            // This post exists, so ask only for the new status
                            wanted.posts.push({
                                id: result.data.posts[id],
                                properties: ['status'],
                            });
                        } else {
                            // This post does not exist yet, so ask for everything
                            wanted.posts.push({
                                id: result.data.posts[id],
                                properties: [],
                            });
                        }
                    }
                }
                // Check the list of comments that have been changed, and see what we want
                if (Object.keys(result.data.comments).length > 0) {
                    for (id in result.data.comments) {
                        if (self.storage.has('comment.' + result.data.comments[id])) {
                            // This comment exists, we don't need it again
                        } else {
                            // This comment does not yet exist, so ask for everything
                            wanted.comments.push({
                                id: result.data.comments[id],
                                properties: [],
                            });
                        }
                    }
                }

                // If there is anything we want, ask for it
                if (wanted.posts.length > 0 || wanted.comments.length > 0) {
                    console.log('app.download() - requesting');
                    self.api.doPost('get_info', null, wanted).then((result) => {
                        if (result && result.data) {
                            // Update the posts and comments, emitting a change event
                            if (result.data.posts) {
                                for (var key in result.data.posts) {
                                    let post = result.data.posts[key];
                                    if (self.storage.has('post.' + key)) {
                                        var post_in_db = self.storage.get('post.' + key);
                                        for (var prop in post) {
                                            post_in_db[prop] = post[prop];
                                        }
                                        self.storage.set('post.' + key, post_in_db, true);
                                    } else {
                                        self.storage.set('post.' + key, post, true);
                                    }
                                }
                            }
                            if (result.data.comments) {
                                for (key in result.data.comments) {
                                    let comment = result.data.comments[key];
                                    self.storage.set('comment.' + key, comment, true);
                                }
                            }
                        }
                    });
                }

                // Update the status so we know when it was last run
                self.storage.set('sync.downloaded', self.GMTToLocal(result.datetime).substring(0, 19));
            } else {
                console.error('An error occurred getting info from the API.');
            }

            // This function runs periodically and should continue to do so, but give the cpu a break of a few seconds
            return window.setTimeout(() => {
                self.download()
            }, self.downloadTimer);
        });
    }

    upload() {
        // This is run automatically on app start, and it will keep on running
        // For every iteration it sees if it needs to upload or download elements (in the background) and does so for 1 element
        // The timeout is controlled by:
        // * this.uploadTimerFast: when something was done, the next sync-tick will happen fast
        // * this.uploadTimerSlow: when there is nothing to do, we give the cpu a longer break before running this again
        // Note: window.setTimeOut is erratic when the app is not in focus; that is just fine
        console.log('app.upload()');
        let self = this;

        // If we're offline, exit rightaway
        if (!self.isOnline()) {
            return window.setTimeout(() => {
                self.upload();
            }, self.uploadTimerSlow);
        }

        // Find a post or comment that hasn't been uploaded yet
        let key = null;
        let type = null;
        let keys = self.storage.keys();
        for (let k in keys) {
            let type_check = (keys[k] + '.').split('.')[0];
            if (type_check == 'post' || type_check == 'comment') {
                // Inspect the status: if that is local it hasn't been uploaded yet
                let element = self.storage.get(keys[k]);
                if (element.storage == 'local') {
                    key = keys[k];
                    type = type_check;
                }
            }
        }

        if (key) {
            // Get the content
            let content = self.storage.get(key);

            // Do the upload, and if all went well, inform, and update the record
            self.api.doPost('post_' + type, key, content).then((result) => {
                // If the result was successful
                if (result && result.data) {
                    if (result.data.result && result.data.result === true) {
                        // Update the status so we know when it was last run
                        self.storage.set('sync.uploaded', self.GMTToLocal().substring(0, 16));

                        // Update the status of the post, emitting a change event
                        console.log('Updating status for ' + key);
                        content.storage = 'uploaded';
                        self.storage.set(key, content, true);
                    }

                    // Call the function again, shortly
                    return window.setTimeout(() => {
                        self.upload()
                    }, self.uploadTimerFast);
                } else {
                    console.error('An error occurred posting data to the API.');

                    // This function runs periodically and should continue to do so, but give the cpu a break of a few seconds
                    return window.setTimeout(() => {
                        self.upload()
                    }, self.uploadTimerSlow);
                }
            });
        } else {
            // Nothing to upload
            // This function runs periodically and should continue to do so, but give the cpu a break of a few seconds
            return window.setTimeout(() => {
                self.upload()
            }, self.uploadTimerSlow);
        }
    }

    cleanup(days = 21) {
        // Cleans up elements if they are more than <days> days old and have been closed
        console.log('app.cleanup(' + days + ',' + localStorage + ')');
        let cutoffDate = this.GMTToLocal(new Date(Date.now() - days * 24 * 60 * 60 * 1000));

        // Loop through the posts in localStorage
        let posts = this.storage.getAll('post.');
        for (let post of posts) {
            if (post.storage == 'uploaded') {
                if (post.createdAt < cutoffDate) {
                    this.storage.remove('post.' + post.id);
                }
            }
        }
    }

    capturePhoto(storage = '#picture', display = '#pictures') {
        // Lets the user take a picture, add it to the post
        console.log('app.capturePhoto(' + storage + ',' + display + ')');
        let self = this;

        if (document.querySelector('#container_app[data-lb-mode]').dataset.lbMode !== 'read') {
            self.camera.capturePhoto(self.generateUUID(), (key) => {
                // Add the image to the display container
                let displayElement = document.querySelector(display);
                if (displayElement) {
                    // Create a new image holder
                    let newPicture = '<img class="picture" src="" id="' + key + '">';
                    displayElement.innerHTML += newPicture;

                    // Have the application insert the image to it
                    self.storage.loadBlob(key, key);
                }

                // Add the key to the input
                let storageElement = document.querySelector(storage);
                if (storageElement) {
                    let picValues = [];
                    if (storageElement.value) {
                        picValues = storageElement.value.split(';');
                    }
                    picValues.push(key);
                    storageElement.value = picValues.join(';');
                }
                return key;
            });
        }
    }

    removePhoto(imageId, storage = '#picture') {
        // Lets the user remove a picture
        console.log('app.removePhoto(' + imageId + ',' + storage + ')');
        let self = this;

        if (document.querySelector('#container_app[data-lb-mode]').dataset.lbMode !== 'read') {
            self.confirm('Wil je deze foto verwijderen?', '', () => {
                // Remove it from the database and the DOM
                self.storage.removeBlob(imageId);
                document.getElementById(imageId).remove();

                // Also remove the text from the storage element
                let storageElement = document.querySelector(storage);
                if (storageElement) {
                    let values = storageElement.value.split(';');
                    values = values.filter(arrayItem => arrayItem !== imageId);
                    storageElement.value = values.join(';');
                }
            });
        }
    }
}