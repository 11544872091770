export default class API {
    constructor() {
        try {
            this.server_path = config.VUE_CONFIG_APP_API_PATH;
            this.server = config.VUE_CONFIG_APP_API;
            this.token = '';

            this.isOnline = window.navigator.onLine;
        } catch (error) {
            console.error(error);
        }
    }

    initialize(storage) {
        console.log('api.initialize()');
        let self = this;

        self.storage = storage;
        self.token = self.storage.get('api.token');

        window.addEventListener('online', () => {
            console.log('app is online');
            self.isOnline = true;
        });
        window.addEventListener('offline', () => {
            console.log('app is offline');
            self.isOnline = false;
        });
    }

    isOnline() {
        return this.isOnline;
    }

    login(server = '', username = '', password = '') {
        console.log('api.login(' + server + ',' + username + ',<password>)');
        let self = this;

        return new Promise(function(resolve, reject) {
            try {
                // Take a trip to the server
                fetch('https://' + server + self.server_path + 'login', {
                        method: 'POST',
                        credentials: 'include',
                        headers: new Headers({
                            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                        }),
                        body: new URLSearchParams({
                            username: username || '',
                            password: password || ''
                        })
                    })
                    .then(
                        (response) => response.json()
                    )
                    .then(
                        (response) => {
                            // In case login returns a valid response, pickup the server and token
                            if (response.status == 200) {
                                if (response && response.data) {
                                    self.server = self.storage.set('api.server', server);
                                    self.token = self.storage.set('api.token', response.data.token);
                                }
                                resolve(response.data);
                            } else {
                                reject(new Error('Could not login.'));
                            }
                        },
                        (error) => {
                            reject(error);
                        }
                    )
                    .catch(error => {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    }

    checkToken() {
        console.log('api.checkToken()');
        let self = this;

        return new Promise(function(resolve, reject) {
            try {
                // Take a trip to the server
                fetch('https://' + self.server + self.server_path + 'check_token', {
                        method: 'POST',
                        credentials: 'include',
                        headers: new Headers({
                            'Authorization': 'Bearer ' + self.token
                        })
                    })
                    .then(
                        (response) => response.json()
                    )
                    .then(
                        (response) => {
                            // In case login returns a valid response return the status
                            if (response.status == 200) {
                                resolve(response.data.status);
                            } else {
                                reject(new Error("Invalid authentication token."));
                            }
                        },
                        (error) => {
                            reject(error);
                        }
                    )
                    .catch((error) => {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    }

    doPost(endpoint, key, content) {
        console.log('storage.doPost(' + endpoint + ',' + key + ',<content>)');
        let self = this;

        return new Promise(function(resolve, reject) {
            try {
                let body = new FormData();
                body.append('json', JSON.stringify(content));
                body.append('key', key);

                fetch('https://' + self.server + self.server_path + endpoint, {
                        method: 'POST',
                        credentials: 'include',
                        headers: new Headers({
                            'Authorization': 'Bearer ' + self.token
                        }),
                        body: body
                    })
                    .then(
                        (response) => {
                            try {
                                if (response.status == 200) {
                                    return response.json();
                                } else {
                                    resolve(false);
                                }
                            } catch (error) {
                                resolve(false);
                            }
                        }
                    )
                    .then(
                        (response) => {
                            // If the response was a valid JSON, resolve
                            resolve(response);
                        },
                        (error) => {
                            console.error(error);
                            reject(error);
                        })
                    .catch((error) => {
                        console.error(error);
                        reject(error);
                    });
            } catch (error) {
                console.error(error);
                reject(error);
            }
        });
    }

    // Possible problems:
    // 0. Some error in this JS function
    // 1. Timeout: server or client is offline
    // 2. Webserver complains: HTTP 404 (not found), 500 (script error), or anything that is not 200 (OK)
    // 3. Pre-flight error, blocking error or user is not authenticated
    // 4. Malformed JSON (possibly because of server reporting PHP warnings / errors)
    // 5. JSON = wellformed but result.status <> 200: application level error
}